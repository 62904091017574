import styled from '@emotion/styled'

import {
  FirmwareLTEDataRequest,
  SubTypeOfHardware,
  Organization,
  Firmware,
  TranslationGroup,
  trans,
  TranslationKey,
} from 'lib/types'
import { getFirmwareSettingsData, getHardwareList, getCurrentFirmwareVersionName } from 'lib/utils/hardwareAndFirmware'
import { PopUpNotifications, Firmwares } from 'models'
import { BORDER1_COLOR, HARDWARE_TYPE_OPTIONS, TITLE_COLOR } from 'lib/constants'
import { ActionButton, StyledDialogActions, StyledDialogTitle } from 'components/widgets'
import { getOrganization } from 'models/api'
import { useGlobal } from 'contexts/GlobalProvider'
import { setTextRange } from 'typescript'
import { Dialog, DialogContent } from '@material-ui/core'

const onHandleUpdateFirmwareOptions = async (
  firmwareOptions: FirmwareLTEDataRequest,
  orgId: string,
  closeModal: () => void,
) => {
  const translation: TranslationGroup = trans.settings()
  const firmwareOptionsCopy = Object.assign({}, firmwareOptions)
  Object.keys(firmwareOptionsCopy).forEach(key => {
    if (firmwareOptionsCopy[key] === 'notUpdate') firmwareOptionsCopy[key] = null
  })
  await PopUpNotifications.tryWithErrorNotifications(
    Firmwares.updateSingleOrGlobal(firmwareOptionsCopy, orgId),
    translation.there_was_a_problem,
  )
  closeModal()
  PopUpNotifications.fireSuccess({
    content: translation.firmware_settings_updated,
  })
}

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  p: {
    margin: '0',
  },
})

const StyledSummaryContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '25px',
})

const StyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
})

const StyledTitle = styled.div(props => ({
  color: props.color ? props.color : TITLE_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  lineHeight: '2.9rem',
  letterSpacing: '0',
  textAlign: 'left',
  fontWeight: 'bold',
}))

type Props = {
  org: Organization | null
  subType: SubTypeOfHardware[]
  newSetting: FirmwareLTEDataRequest
  firmwares: Firmware[]
  closeModal: () => void
}

function FirmwareSettingsModal({ org, subType, newSetting, firmwares, closeModal }: Props) {
  const settingList = getFirmwareSettingsData(newSetting, subType) as SubTypeOfHardware[]
  const hardwareList = getHardwareList(settingList)
  const translation: TranslationGroup = trans.merge(TranslationKey.SETTINGS)
  const { selectedOrg, setSelectedOrg } = useGlobal()
  const settings: TranslationGroup = trans.settings()

  return (
    <Dialog style={{ animationFillMode: 'forwards' }} open={true} onClose={() => null} maxWidth="sm" fullWidth>
      <StyledDialogTitle>{settings.firmware_settings_change}</StyledDialogTitle>
      <DialogContent>
        <StyledContainer>
          <StyledSeparator />
          <div>{translation.confirm_update}</div>
          <StyledTitle>{translation.property}</StyledTitle>
          <div>{org?.name}</div>
          <StyledTitle>{translation.settings_to_apply}</StyledTitle>
          {Object.keys(hardwareList).map(key => (
            <>
              <StyledTitle>{HARDWARE_TYPE_OPTIONS.find(hT => hT.value === key)?.name}</StyledTitle>
              {hardwareList[key].map(subType => (
                <>
                  <StyledTitle>{subType.name}</StyledTitle>
                  <StyledSummaryContainer>
                    <StyledTitle color="#DD3302"> {translation.old}:</StyledTitle>
                    <p>{getCurrentFirmwareVersionName(org?.props?.firmware || {}, subType, firmwares)}</p>
                  </StyledSummaryContainer>
                  <StyledSummaryContainer>
                    <StyledTitle color="#15A034"> {translation.new}:</StyledTitle>
                    <p>{getCurrentFirmwareVersionName(newSetting, subType, firmwares)}</p>
                  </StyledSummaryContainer>
                  <StyledSeparator />
                </>
              ))}
            </>
          ))}
          <StyledDialogActions>
            <ActionButton onClick={() => closeModal()}>{translation.cancel}</ActionButton>
            <ActionButton
              onClick={async () => {
                await onHandleUpdateFirmwareOptions(newSetting, org?.id || '', closeModal)
                if (org?.id) {
                  getOrganization(org?.id).then(data => setSelectedOrg(data))
                }
              }}
            >
              {translation.apply}
            </ActionButton>
          </StyledDialogActions>
        </StyledContainer>
      </DialogContent>
    </Dialog>
  )
}

export default FirmwareSettingsModal
