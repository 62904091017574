import React, { useEffect, useState } from 'react'
// import { TGetVenueOptions, getVenueMaker, showVenue } from '@mappedin/mappedin-js'
import '@mappedin/mappedin-js/lib/index.css'
import { TCameraTarget, getMapData, show3dMap } from '@mappedin/mappedin-js'
import { PRIMARY_COLOR } from 'lib/constants'
import { AlertSession } from 'lib/types'
import { useMappedInContext } from 'contexts/MappedInProvider'
import { useGlobal } from 'contexts/GlobalProvider'

// export const options = {
//   mapId: '66969b04323078000bffec10',
//   key: '65ce67d9a391db7b4e9209ba',
//   secret: 'd6f13a5a11c7689aaf411692469798d1a6c6b2a0243000dc6b422c66487771cc',
// }

type Props = {
  alertSession: AlertSession
}

const VenueMap = ({ alertSession }: Props) => {
  const [initialized, setInitialized] = useState(false)
  const [mapData, setMapData] = useState<any>()
  const [mapView, setMapView] = useState<any>(null)
  const [floor, setFloor] = useState<any>()
  const [floors, setFloors] = useState<any>([])
  const [clickedCoord, setClickedCoord] = useState<any>(null)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const { selectedOrg } = useGlobal()

  useEffect(() => {
    async function init() {
      console.log('Running mi init')
      let options: any = null
      if (selectedOrg?.mappedin) {
        const { mapId, key, secret } = selectedOrg.mappedin
        options = { mapId, key, secret }
      }

      if (!options) {
        console.log('~no options - returning', options)
        return
      }

      const mapData = await getMapData(options)

      const floors = mapData.getByType('floor')

      const mapView = await show3dMap(document.getElementById('mappedin-map') as HTMLDivElement, mapData)
      // Set the floor to Floor.id 'm_987654321'. first floor
      console.log('mi-floors:', floors)
      floors.forEach(f => {})
      let floor = floors.filter(f => f.name == alertSession?.lastLocation?.floor)[0]

      if (floor) mapView.setFloor(floor.id)

      const markerTemplate = `
          <div style="display: grid; place-content: center; background-color: black; border-radius: 100px; width: 22px; height: 22px;">   
            <div class="marker" style="display: grid; place-content: center;">
                <img src="/Beacons_Icon.svg" alt="Marker Image"  />
            </div>
          </div>

 `
      mapView.Labels.all()
      // Set each space to be interactive.
      mapData.getByType('space').forEach(space => {
        console.log({ space })
        mapView.updateState(space, {
          interactive: true,
        })
      })

      const defaultCameraPosition: TCameraTarget = {
        bearing: mapView.Camera.bearing,
        pitch: mapView.Camera.pitch,
        zoomLevel: mapView.Camera.zoomLevel,
        center: mapView.Camera.center,
      }

      // Set each space to be interactive and its hover color.
      mapData.getByType('space').forEach((space, i) => {
        mapView.updateState(space, {
          //   color: i % 2 == 1 ? 'red' : 'blue',
          interactive: true,
          hoverColor: '#D3D3D3',
        })
      })

      mapData.getByType('space').forEach((space, i) => {
        if (space.name == alertSession?.lastLocation?.room) {
          mapView.updateState(space, {
            //   color: i % 2 == 1 ? 'red' : 'blue',
            interactive: true,
            hoverColor: '#D3D3D3',
            color: PRIMARY_COLOR,
          })
        }
      })

      let focused: boolean = false

      // Act on the click event to focus on the Space that was clicked or reset the camera.
      mapView.on('click', async event => {
        console.log('clicked event:', event)
        setClickedCoord(event.coordinate)

        mapView.Camera.focusOn(event.spaces[0])
        focused = true
        // }
      })

      setLastLocation(alertSession?.lastLocation)
      setMapView(mapView)
    }

    const sameLocation =
      alertSession?.lastLocation?.floor == lastLocation?.floor && alertSession?.lastLocation?.room == lastLocation?.room

    if (sameLocation) {
      return
    }

    init()
    return () => {}
  }, [initialized, alertSession]) // Include venueOptions in the dependency array

  return <div style={{ width: '800px', height: '800px' }} id="mappedin-map" />
}

export default VenueMap
