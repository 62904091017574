/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { shallowEqual } from 'react-redux'
import { useEffect, useState } from 'react'
import { Box, IconButton, Input, LinearProgress } from '@material-ui/core'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import { DetailSliderContent } from 'components/partials'
import { AlertChatEvents } from './AlertChatEvents'
import CondensedInfoGadget from 'components/partials/CondensedInfoGadget'
import SolveAlertDialog from 'components/partials/SolveAlertDialog'
import { ActionButton, ConditionalRender } from 'components/widgets'
import { GRAY3_COLOR, HealthLabelsKeys, JWT_TOKEN, TEXTFIELD_LABEL_COLOR } from 'lib/constants'
import { AlertEvent, AlertSession, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { AlertEvents, NavState, Orgs, AlertSessions } from 'models'
import * as utils from 'components/partial-pages/AlertsView/utils'
import { DetailRow } from './utils'
import {
  actionRowStyles,
  alertEventsWrapperStyles,
  alertEventCountStyles,
  alertHistoryTitleStyles,
  detailHeadingStyles,
  detailStyles,
  linearProgressStyles,
} from 'styles'
import { getAlertTimes, formatLocalTime } from 'lib/utils'
import { v4 as getUuidv4 } from 'uuid'
import { getUnixTime } from 'date-fns'
import jwt_decode from 'jwt-decode'
import { isAdmin, isDispatcher, isPortalUser, isResponder } from 'lib/utils/auth'
import { ACTION_TYPE } from 'lib/constants/keys'
import { HealthIcon, HealthStatus } from 'components/widgets/HealthIcon'
import LocationMap from 'components/partial-pages/AlertsView/Partials/LocationMap'
import VenueMap from 'components/partial-pages/AlertsView/Partials/VenueMap'
import VenueDrawer from 'components/partial-pages/AlertsView/Partials/VenueDrawer'
import { useGlobal } from 'contexts/GlobalProvider'

export const AlertDetailDrawer = ({
  alertSession: _alert,
  close: handleDrawerClose,
}: {
  alertSession: AlertSession
  close: Function
}) => {
  const alertSessionsById = AlertSessions.use(({ alertSessionsById }) => alertSessionsById)
  const alertSession = alertSessionsById[_alert.id] || _alert // get up-to-date alertSession-session if available
  const [mapVisible, setMapVisible] = useState(true)
  const [showDialog, setShowDialog] = useState(false)
  const [showArrivedDialog, setShowArrivedDialog] = useState(false)
  const [responderNames, setResponderNames] = useState<string[]>([])
  const [filteredAlertEvents, setFilteredAlertEvents] = useState<AlertEvent[]>([])
  const [map, setMap] = useState<string | null>(null)
  const [mapAvailable, setMapAvailable] = useState(map ? true : false)
  const [previousMap, setPreviousMap] = useState<string | null>(null)

  const { selectedOrg } = useGlobal()

  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const property = orgsById[alertSession.orgId]
  const { alertEvents, loading: alertEventsLoading } = AlertEvents.use(({ alertEvents, loading }) => {
    return { alertEvents, loading }
  }, shallowEqual)

  const [comment, setComment] = useState('')
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [movingThroughHistory, setMovingThroughHistory] = useState(false)
  const arrivedDisabled = false // alertSession.activeRespondersCount > 0
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_DRAWER)

  function handleScroll(e: any) {
    const scrolledToBottom = Math.abs(e.target.clientHeight - (e.target.scrollHeight - e.target.scrollTop)) <= 1
    // It's ok if the difference is a pixel or less.
    setScrolledToBottom(scrolledToBottom)
    setMovingThroughHistory(!scrolledToBottom)
  }

  function getEventCount() {
    const filteredEvents = alertEvents.filter(event => {
      if (
        // blank map event
        (event.attributes.action === null &&
          event.attributes?.map !== null &&
          event.attributes?.beacons?.length === 0 &&
          event.attributes?.location?.integration === null) ||
        // Blank event
        (event.attributes.action === null &&
          event.attributes?.message === null &&
          event.attributes?.beacons?.length === 0 &&
          event.attributes?.location?.integration === undefined)
      )
        return false
      else return true
    })
    return filteredAlertEvents.length
  }

  // Admin, responder, and portal user can add comments
  const shouldShowAddComment =
    _alert.status !== HealthLabelsKeys.RESOLVED &&
    (isAdmin(_alert.orgId) || isResponder(_alert.orgId) || isPortalUser(_alert.orgId))

  useEffect(() => {
    NavState.setSelectedAlertSessionId(alertSession.id)
  }, [alertSession.id])

  useEffect(() => {
    const responders = alertEvents.reduce((acc: string[], ae: AlertEvent) => {
      const asn = ae.attributes.alertSourceName
      if (ae.attributes.role !== 'initiator' && !ae.attributes.location?.integration && asn && !acc.includes(asn)) {
        acc.push(asn)
      }
      return acc
    }, [])
    setResponderNames(responders)
    setScrolledToBottom(false)
    const filteredEvents = alertEvents.filter(event => {
      if (
        // blank map event
        (event.attributes.action === null &&
          event.attributes?.map !== null &&
          event.attributes?.beacons?.length === 0 &&
          event.attributes?.location?.integration === null) ||
        // Blank event
        (event.attributes.action === null &&
          event.attributes?.message === null &&
          event.attributes?.beacons?.length === 0 &&
          event.attributes?.location?.integration === undefined)
      )
        return false
      else return true
    })
    setFilteredAlertEvents(filteredEvents)
  }, [alertEvents])

  const { startTime, endTime } = getAlertTimes(alertSession)

  const sendComment = () => {
    if (comment) {
      const token = localStorage.getItem(JWT_TOKEN) || ''
      const decoded = jwt_decode(token) as any
      const userId = JSON.parse(decoded.sub).id

      const alertEvent = {
        alertEvent: {
          id: getUuidv4(),
          alertSessionId: _alert.id,
          userId,
          orgId: _alert.orgId,
          timestamp: getUnixTime(new Date()),
          message: comment,
          beacons: [],
          role: 'responder',
          location: {},
        },
      }

      AlertEvents.createAlertEvent(alertEvent)

      setComment('')
    }
  }

  function scrollToBottomHandler() {
    setScrolledToBottom(false)
    setMovingThroughHistory(false)
  }

  const getHealthStatus = () => {
    const health = utils.getAlertHealth(alertSession)

    let status = HealthStatus.UNKNOWN
    switch (health) {
      case HealthLabelsKeys.ACTIVE_NO_RESPONSE:
        status = HealthStatus.ACTIVE_NO_RESPONSE
        break
      case HealthLabelsKeys.ACTIVE_IN_PROGRESS:
        status = HealthStatus.MODERATE
        break
      case HealthLabelsKeys.RESOLVED:
        status = HealthStatus.HEALTHY
        break
    }
    return <HealthIcon label={translation[health]} status={status} />
  }

  const getMap = (aes: AlertEvent[]) => {
    return aes.filter(ae => ae?.attributes?.map !== null).pop()?.attributes?.map || null
  }

  useEffect(() => {
    const newMap = getMap(alertEvents)
    map ? setPreviousMap(map) : setPreviousMap(newMap) // check to see if this is the first map
    setMap(newMap)
  }, [alertEvents])

  useEffect(() => {
    map || previousMap ? setMapAvailable(true) : setMapAvailable(false)
    // setPreviousMap(map)
  }, [map, previousMap])

  return (
    <Box
      id="AlertDetailDrawer"
      style={{
        height: '100%',
        backgroundColor: `${GRAY3_COLOR}`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {selectedOrg?.mappedin && selectedOrg.mappedin?.isEnabled ? (
        <VenueDrawer
          alertSession={alertSession}
          visible={mapVisible}
          mapAvailable={alertSession?.lastBeacons?.length ? true : false} // Check if beacon location, which maps to mappedin space, is available
          map={map}
          previousMap={previousMap}
        />
      ) : (
        !alertEventsLoading && (
          <LocationMap visible={mapVisible} mapAvailable={mapAvailable} map={map} previousMap={previousMap} />
        )
      )}

      <DetailSliderContent
        title={translation.Alert_Detail}
        close={() => {
          handleDrawerClose()
        }}
      >
        <ul css={detailStyles}>
          <DetailRow title={translation.property} content={property?.name} />
          <DetailRow title={translation.property_id} content={alertSession.orgId} />
          <DetailRow title={translation.Status} content={getHealthStatus()} />
          <DetailRow title={translation.Start} content={formatLocalTime(startTime)} />
          <DetailRow title={translation.End} content={formatLocalTime(endTime, translation.ongoing) as string} />
          <DetailRow title={translation.Resolved_In} content={utils.getResolution(alertSession)} />
          <DetailRow title={translation.Resolved_By} content={alertSession.resolverName || ''} />
          <DetailRow title={translation.Alert_Source} content={alertSession.initiatorName} />
          <DetailRow title={translation.Participants} content={responderNames.join(', ')} />
          <DetailRow title={translation.Alert_ID} content={alertSession.id} />
        </ul>

        {/* TODO: */}
        <ConditionalRender
          condition={
            utils.getAlertHealth(alertSession) !== HealthLabelsKeys.RESOLVED &&
            alertSession.orgId &&
            (isAdmin(alertSession.orgId) || isDispatcher(alertSession.orgId))
          }
        >
          <div css={actionRowStyles}>
            <ActionButton
              disabled={arrivedDisabled}
              onClick={() => {
                setMapVisible(false)
                setShowArrivedDialog(true)
              }}
            >
              {translation.mark_arrived}
            </ActionButton>
            <ActionButton
              onClick={() => {
                setMapVisible(false)
                setShowDialog(true)
              }}
            >
              {translation.Resolve_Alert}
            </ActionButton>
          </div>
        </ConditionalRender>
      </DetailSliderContent>
      <div css={alertHistoryTitleStyles}>
        <h2 css={detailHeadingStyles}>{translation.alert_history}</h2>
        <div css={alertEventCountStyles}>{`${translation.alert_event_count} (${getEventCount()})`}</div>
        <ConditionalRender condition={movingThroughHistory && !scrolledToBottom}>
          <IconButton size={`small`} title={translation.view_newest} onClick={scrollToBottomHandler}>
            <VerticalAlignBottomIcon fontSize={`inherit`} />
          </IconButton>
        </ConditionalRender>
      </div>
      <Box
        style={{
          height: '100%',
          backgroundColor: `${GRAY3_COLOR}`,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <div css={{ ...alertEventsWrapperStyles }} onScroll={handleScroll}>
          {alertEventsLoading ? (
            <LinearProgress css={linearProgressStyles} />
          ) : (
            <AlertChatEvents
              events={filteredAlertEvents}
              movingThroughHistory={movingThroughHistory}
              scrolledToBottom={scrolledToBottom}
            />
          )}
          <ConditionalRender condition={utils.getAlertHealth(alertSession) === HealthLabelsKeys.RESOLVED}>
            {/* TODO: replace this with something not reliant on "formatted" alerts */}
            <CondensedInfoGadget alertSession={alertSession} title={translation.resolved_info} handleClick={null} />
          </ConditionalRender>
        </div>
        {shouldShowAddComment && (
          <div css={{ ...alertHistoryTitleStyles, marginTop: 'auto' }}>
            <Input
              id="comment-value"
              disableUnderline
              fullWidth
              placeholder={translation.add_comment}
              value={comment}
              onChange={e => setComment(e.target.value)}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  sendComment()
                }
              }}
              css={{
                color: TEXTFIELD_LABEL_COLOR,
                fontFamily: 'Montserrat',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: '1.5rem',
                letterSpacing: '0.1rem',
                textAlign: 'left',
                marginBottom: '20px',
              }}
            />
            <ActionButton width="110px" height="28px" onClick={sendComment}>
              {translation.send}
            </ActionButton>
          </div>
        )}
      </Box>
      {/* TODO: replace this too */}
      <SolveAlertDialog
        props={{
          open: showDialog,
          handleClose: () => {
            setShowDialog(false)
            setMapVisible(true)
          },
          setMapVisible: setMapVisible,
          selectedRow: alertSession,
          alertLocation: { value: alertSession.orgId },
          action: ACTION_TYPE.CLOSE,
        }}
      />
      <SolveAlertDialog
        props={{
          open: showArrivedDialog,
          handleClose: () => {
            setShowArrivedDialog(false)
            setMapVisible(true)
          },
          setMapVisible: setMapVisible,
          selectedRow: alertSession,
          alertLocation: { value: alertSession.orgId },
          action: ACTION_TYPE.ARRIVED,
        }}
      />
    </Box>
  )
}
