/** @jsxImportSource @emotion/react */
import { shallowEqual } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import isNil from 'lodash/isNil'
import { ViewWrapper, PageWrapper, ViewSpacerWrapper, ActionButton } from 'components/widgets'
import { AlertSessions, DetailsDrawer, PopUpNotifications } from 'models'
import { AlertSession, RouteParams, SortOrder, TableState, TableToolEvent, trans, TranslationGroup } from 'lib/types'
import { OpenAlertCards } from 'components/partial-pages/AlertsView'
import * as utils from './utils'
import { invertBinaryEnum } from 'lib/utils/common'
import { PreConfiguredTableView } from 'components/partials/TableViewV2'
import { AlertDetailDrawer } from 'components/partials/DrawerDetails'
import { useHistory, useParams } from 'react-router-dom'
import { AlertsColumnKeys, ALERTS_PATH, ALL_ORGS_SELECTED } from 'lib/constants'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { useAlerts } from 'contexts/AlertsProvider'
import { getRequestV5 } from 'models/api/rest'
import { useGlobal } from 'contexts/GlobalProvider'
import { sort } from 'semver'
import { table } from 'console'
import VenueMap from './Partials/VenueMap'

function AlertsView() {
  const common: TranslationGroup = trans.common()
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()

  const { selectedOrgId } = useGlobal()

  const { alertSessions, setAlertSessions, loading, setLoading, tableState, setTableState, pagination, setPagination } =
    useAlerts()

  const legacyAlertSessions = AlertSessions.use().alertSessions

  function handleToolEvent(toolEvent: TableToolEvent<string, AlertsColumnKeys>) {
    console.log({ toolEvent })

    if (toolEvent.filterByRequest) {
      setLoading(true)
      getRequestV5({
        endpoint: '/alert_sessions',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            toolEvent.filterByRequest != utils.getFilterOptions()[0] // not all alerts
              ? { field: 'status', value: toolEvent.filterByRequest }
              : undefined,
          searchBy: { value: tableState.searchBy },
        },
      }).then(response => {
        const data = response.data

        setAlertSessions(data.data)
        setPagination(data.meta)
        setLoading(false)
      })
    } else if (toolEvent.searchByRequest || toolEvent.searchByRequest == '') {
      setLoading(true)
      getRequestV5({
        endpoint: '/alert_sessions',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'status', value: tableState.filterBy }
              : undefined,
          searchBy: { value: toolEvent.searchByRequest },
        },
      }).then(response => {
        const data = response.data
        setAlertSessions(data.data)
        setPagination(data.meta)
        setLoading(false)
        // setTableTools({ ...tableTools, pagination: data.meta })
      })
    } else if (toolEvent.sortByRequest) {
      console.log('so', toolEvent.sortByRequest)

      setLoading(true)
      getRequestV5({
        endpoint: '/alert_sessions',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'status', value: tableState.filterBy }
              : undefined,
          searchBy: { value: tableState.searchBy },
          sortBy: { field: toolEvent.sortByRequest.field, order: toolEvent.sortByRequest.order },
        },
      }).then(response => {
        const data = response.data
        console.log('the data', data)
        setAlertSessions(data.data)
        setPagination(data.meta)
        setLoading(false)
        // setTableTools({ ...tableTools, pagination: data.meta })
      })
    }

    // set table state after tool event
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  function handleHeaderClick(header: string) {
    console.log({ header })
    // I will need to ignore certain header clicks as sorting is missing for some columns
    const excludeList = ['Time']
    if (excludeList.includes(header)) return
    setLoading(true)
    getRequestV5({
      endpoint: '/alert_sessions',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // not all alerts
            ? { field: 'status', value: tableState.filterBy }
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: {
          field: header,
          order: invertBinaryEnum(tableState.sortBy.order),
        },
      },
    }).then(response => {
      const data = response.data
      console.log('Data considered:', { data })

      setAlertSessions(data.data)
      setPagination(data.meta)
      setLoading(false)
      // setTableTools({ ...tableTools, pagination: data.meta })
    })

    setTableState(state => {
      console.log({ state })
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleDrawerClose() {
    history.push({
      pathname: `${ALERTS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: AlertSession) {
    if (row.id != id) {
      history.push({
        pathname: `${ALERTS_PATH}/${row.id}`,
        search: query.toString(),
      })
    }

    DetailsDrawer.show({
      drawerComponent: AlertDetailDrawer,
      drawerProps: {
        alertSession: row,
        close: handleDrawerClose,
      },
    })
  }

  function handlePageChange(page: any, newPage: any) {
    console.log('page:', page, 'np:', newPage)
    setLoading(true)
    getRequestV5({
      endpoint: '/alert_sessions',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        pagination: { currentPage: newPage },
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // all alerts selected in filter?
            ? { field: 'status', value: tableState.filterBy }
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: {
          field: tableState.sortBy.field,
          order: tableState.sortBy.order,
        },
      },
    }).then(response => {
      const data = response.data
      setAlertSessions(data.data)
      setPagination(data.meta)
      setLoading(false)
    })
  }

  /* Using legacy alert sessions model to display open alert cards. 
     Previously, this was linked to the table data, but search, sort, 
     and filter can cause the cards to disappear if the open alerts aren't
     in the first page payload.
  */
  const filteredAlertSessions =
    selectedOrgId !== ALL_ORGS_SELECTED
      ? legacyAlertSessions.filter(as => {
          if (selectedOrgId === as.orgId) return true
          else return false
        })
      : legacyAlertSessions

  return (
    <PageWrapper>
      <ViewWrapper>
        <ViewSpacerWrapper>
          <RenderCounter name={'AlertsView'} />
          <OpenAlertCards alertSessions={filteredAlertSessions} />
          <PreConfiguredTableView
            sortedData={alertSessions}
            tableState={tableState}
            columnConfigs={utils.getColumnConfigs()}
            hideFilter
            filterOptions={utils.getFilterOptions()}
            sortOptions={utils.getSortOptions()}
            getRowToolTip={utils.getAlertTooltip}
            onHeaderClick={handleHeaderClick}
            onRowClick={handleRowClick}
            onToolEvent={handleToolEvent}
            selectedRowId={id}
            loading={loading}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            <ActionButton onClick={() => utils.handleDownloadCSV(selectedOrgId || '')}>
              {common.download_csv}
            </ActionButton>
          </PreConfiguredTableView>
        </ViewSpacerWrapper>
      </ViewWrapper>
    </PageWrapper>
  )
}

export default memo(AlertsView)
