/** @jsxImportSource @emotion/react */
import { useState, ChangeEvent, useEffect } from 'react'
import styled from '@emotion/styled'
import { Button, FormControlLabel, IconButton, Switch, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { ConfirmationDialog } from 'components/partials'
import { ConditionalRender, StyledFormLabel, StyledInput } from 'components/widgets'
import { INTEGRATIONS, INTEGRATION_MAP, PRIMARY_COLOR } from 'lib/constants'
import { ExternalIntegration, Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ExternalIntegrations, NavState } from 'models'
import { rowContainerStyles, titleTextStyles } from 'styles'
import { useGlobal } from 'contexts/GlobalProvider'
import { MappedIn } from 'lib/types/mappedIn'
import { getOrganization, updateOrganization } from 'models/api'
import { update } from 'lodash'
import { unflattenOrg, updateOrg } from 'models/Orgs'

const DeleteButton = styled(IconButton)({
  marginTop: '0 !important',
  paddingTop: 0,
  paddingBottom: 0,
})

const FormContainer = styled.div({
  position: 'relative',
  display: 'flex',
  padding: '0 2.4rem',
  marginBottom: '5.0rem',
  flexDirection: 'column',
  flex: '0 0 33.3333%',
  borderRight: '1px solid #E3E3E3',
  boxSizing: 'border-box',
})

const SpaceBetweenContainer = styled.div({
  justifyContent: 'space-between',
  display: 'flex',
  flex: 1,
})

const StyledButton = styled(Button)({
  color: PRIMARY_COLOR,
  padding: '8px 20px',
  border: `2px solid ${PRIMARY_COLOR}`,
  boxSizing: 'border-box',
  fontFamily: 'Montserrat',
  boxShadow: '0px 4px 8px rgba(20, 48, 72, 0.1)',
  fontSize: '1.0rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.2rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginRight: '20px',
  cursor: 'pointer',
  borderRadius: '2px',
})

const SubHeader = styled.h3({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  letterSpacing: '0.1rem',
  lineHeight: '1.5rem',
  color: PRIMARY_COLOR,
  textTransform: 'uppercase',
})

const SwitchLabel = styled.div({
  fontSize: '1.0rem',
})

const TitleRowContainer = styled.div({
  marginBottom: '5.0rem',
})

const Toggle = styled.div({
  flex: 1,
})

const Toggles = styled.div({
  display: 'flex',
})

const ToggleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'rgba(48, 188, 176, 1)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(48, 188, 176, 0.33)',
    },
  },
  checked: {},
  track: {},
})(Switch)

type Props = {
  org: Organization | null
}

export function MappedInIntegrationForm({ org }: Props) {
  const translation: TranslationGroup = trans.merge(TranslationKey.SETTINGS)
  // const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { setSelectedOrg } = useGlobal()

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [mappedIn, setMappedIn] = useState<MappedIn | null>(null)

  useEffect(() => {
    setMappedIn(org?.mappedin || null)
  }, [org])

  const handleEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
    //setIntegration({ ...integration, name, props: { ...integration.props, isEnabled: event.target.checked } })
    setMappedIn({ ...mappedIn, isEnabled: !mappedIn?.isEnabled } as MappedIn)
  }

  const handleFormSubmit = () => {
    if (org) {
      updateOrganization({ ...org, mappedin: mappedIn }).then(data => {
        console.log('~successfully updated mappedin')
        setSelectedOrg(data.data)
      })
    }
  }

  const delTip = `${translation.remove_integration} ${'MappedIn'}`

  return (
    <>
      <ConfirmationDialog
        title={translation.delete_integration}
        description={translation.confirm_delete_integration}
        open={openConfirmModal}
        onConfirm={() => {
          setOpenConfirmModal(false)
          // handleDelete(integration.id)
        }}
        handleClose={() => setOpenConfirmModal(false)}
      />
      <FormContainer>
        <TitleRowContainer>
          <SpaceBetweenContainer>
            <h3 css={titleTextStyles}>{'MappedIn'}</h3>
          </SpaceBetweenContainer>
        </TitleRowContainer>
        <div css={rowContainerStyles}>
          <Toggles>
            <Toggle>
              <SubHeader>{translation.activate}</SubHeader>
              <FormControlLabel
                control={
                  <ToggleSwitch checked={mappedIn?.isEnabled || false} onChange={handleEnabledChange} name="activate" />
                }
                label={<SwitchLabel>{mappedIn?.isEnabled ? translation.enabled : translation.disabled}</SwitchLabel>}
              />
            </Toggle>
          </Toggles>
        </div>
        <div css={rowContainerStyles}>
          <StyledFormLabel>
            {'Map ID'}
            <StyledInput
              id={`mappedin-map`}
              required
              disableUnderline
              fullWidth
              placeholder={translation.enter_url}
              value={mappedIn?.mapId}
              onChange={e => setMappedIn({ ...mappedIn, mapId: e.target.value } as MappedIn)}
              inputProps={{ maxLength: 255 }}
            />
          </StyledFormLabel>
        </div>
        <div css={rowContainerStyles}>
          <StyledFormLabel>
            {translation.apiKey}
            <StyledInput
              id={`mappedin-key`}
              required
              disableUnderline
              fullWidth
              placeholder={translation.enter_apikey}
              value={mappedIn?.key}
              onChange={e => setMappedIn({ ...mappedIn, key: e.target.value } as MappedIn)}
              inputProps={{ maxLength: 255 }}
            />
          </StyledFormLabel>
        </div>
        <div css={rowContainerStyles}>
          <StyledFormLabel>
            {'API SECRET'}
            <StyledInput
              id={`mappedin-secret`}
              disableUnderline
              fullWidth
              placeholder={translation.enter_property_code}
              value={mappedIn?.secret}
              onChange={e => setMappedIn({ ...mappedIn, secret: e.target.value } as MappedIn)}
              inputProps={{ maxLength: 255 }}
            />
          </StyledFormLabel>
        </div>

        <div css={rowContainerStyles}>
          <StyledButton onClick={() => null}>{translation.cancel}</StyledButton>
          <StyledButton onClick={handleFormSubmit}>{translation.apply_changes}</StyledButton>
        </div>
      </FormContainer>
    </>
  )
}
