/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import BulkBeaconInstall from './BulkBeaconInstall'
import FirmwareSettings from './FirmwareSettings'
import { StyledHeader, StyledMetaData } from 'components/widgets'
import { ExternalIntegrationsSettings } from './ExternalIntegrationsSettings'
import { isSuperAdmin } from 'lib/utils/auth'
import { Orgs } from 'models'
import { ALL_ORGS_SELECTED, BORDER1_COLOR } from 'lib/constants'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import GeofenceSettings from './GeofenceSettings'
import { useGlobal } from 'contexts/GlobalProvider'
import { getOrganization } from 'models/api'
import { useEffect, useState } from 'react'

const StyledSettingsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontFamily: 'Montserrat',
  lineHeight: '1.5em',
  button: {
    marginTop: '25px',
  },
})

const StyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
  marginTop: '38px',
  marginBottom: '40px',
})

export const GeneralSettingsContent = () => {
  const translation: TranslationGroup = trans.group(TranslationKey.HEADER)

  // const selectedOrg = Orgs.useSelectedOrg()
  const [settingsOrg, setSettingsOrg] = useState<Organization | null>(null)
  // const { selectedOrg } = useGlobal()
  const { selectedOrgId } = useGlobal()
  useEffect(() => {
    if (selectedOrgId && selectedOrgId !== ALL_ORGS_SELECTED) {
      getOrganization(selectedOrgId).then(data => setSettingsOrg(data))
    }
  }, [selectedOrgId])

  return (
    <StyledSettingsWrapper>
      {settingsOrg && (
        <div css={{ marginBottom: '16px' }}>
          <StyledHeader>{settingsOrg.name}</StyledHeader>
          <StyledMetaData>id: {settingsOrg.id}</StyledMetaData>
        </div>
      )}
      <StyledHeader>{translation.settings}</StyledHeader>
      <FirmwareSettings />
      <StyledSeparator />
      <GeofenceSettings org={settingsOrg} setSettingsOrg={setSettingsOrg} />
      <StyledSeparator />
      {isSuperAdmin() && (
        <>
          <ExternalIntegrationsSettings org={settingsOrg} />
          <StyledSeparator />
        </>
      )}
      <BulkBeaconInstall />
    </StyledSettingsWrapper>
  )
}
