import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  User,
  trans,
  TranslationGroup,
  TranslationKey,
} from 'lib/types'
import { createSortOption } from 'lib/utils/common'
import { generateCSV } from 'lib/utils/csv'
import { Orgs } from 'models'
import { formatDistanceToNow, parseTime, getNameFromUser } from 'lib/utils'
import { differenceInMilliseconds } from 'date-fns'
import { EditUserRoleButton } from 'components/partials'
import { Button } from '@material-ui/core'
import { RemoveUserButton } from 'components/partial-pages/AdminView/Partials'
import { UsersColumnKeys } from 'lib/constants/keys/users'

// types
// export enum UsersColumnKeys {
//   Name = 'Name',
//   Email = 'Email',
//   Provider = 'Provider',
//   LastCheckIn = 'Last_check_in',
//   Membership = 'Membership',
//   Roles = 'Roles',
//   Remove = 'Remove',
//   View = 'View',
// }

export function getFilterOptions() {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  return [translation.all_users, translation.no_membership]
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<UsersColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  return _.flatten(
    _.map(
      _.filter(
        UsersColumnKeys,
        c => c !== UsersColumnKeys.Roles && c !== UsersColumnKeys.Remove && c !== UsersColumnKeys.View,
      ),
      c => [
        createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
        createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
      ],
    ),
  )
}

export function searchUsers(users: User[], searchBy: string): User[] {
  // This function is very expensive, only execute if necessary
  // TODO: Look for ways to improve efficiency
  if (searchBy.length < 2) return users
  const searchArray = _.flatten(
    users.map(user => [
      [user.attributes.email, user.id],
      [user.attributes.firstName, user.id],
      [user.attributes.lastName, user.id],
      [user.attributes.provider, user.id],
      [getMembershipFromUser(user), user.id],
    ]),
  ) as string[][]

  const searchResults = fuzzySearch(searchBy, searchArray)

  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return users.filter(user => searchResultsIds.includes(user.id))
}

export function getFilterUserComparison(user: User, filterBy: string) {
  // if (!getFilterOptions().includes(filterBy)) throw new Error()
  if (filterBy === getFilterOptions()[0]) return true
  return _.isEmpty(user.attributes.membership)
}

export function getMembershipFromUser(user: User, joinChar?: string) {
  if (!user.attributes.membership) return ''
  return user.attributes.membership.map(o => o.name).join(joinChar || ', ')
}

export function getGrantsFromUser(user: User, joinChar?: string) {
  if (!user.attributes.grants) return ''
  return user.attributes.grants.map(o => o.name).join(joinChar || ', ')
}

export const getAdminUsersColumnKeysConfig = (
  orgId: string,
  handleRowClick: any,
): TableColumnConfig<UsersColumnKeys, User>[] => {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()
  return [
    {
      header: UsersColumnKeys.Name,
      label: common[UsersColumnKeys.Name],
      renderFn: (user: User) => getNameFromUser(user),
    },
    {
      header: UsersColumnKeys.Email,
      label: common[UsersColumnKeys.Email],
      renderFn: (user: User) => user.attributes.email,
    },
    {
      header: UsersColumnKeys.Provider,
      label: translation[UsersColumnKeys.Provider],
      renderFn: (user: User) => (user.attributes.provider === 'identity' ? common.password : user.attributes.provider),
    },
    {
      header: UsersColumnKeys.LastCheckIn,
      label: common[UsersColumnKeys.LastCheckIn],
      renderFn: (user: User) => {
        return user.attributes.isOnline
          ? translation.online_now
          : user.attributes.lastCheckedInAt
          ? formatDistanceToNow(user.attributes.lastCheckedInAt)
          : ''
      },
    },
    {
      header: UsersColumnKeys.Membership,
      label: common[UsersColumnKeys.Membership],
      renderFn: (user: User) => getMembershipFromUser(user),
    },
    {
      header: UsersColumnKeys.Roles,
      label: common[UsersColumnKeys.Roles],
      renderFn: (user: User) => <EditUserRoleButton userId={user.id} orgId={orgId} />,
      sortable: false,
      requiresOrg: true,
    },
    {
      header: UsersColumnKeys.Remove,
      label: '',
      renderFn: (user: User) => <RemoveUserButton user={user} orgId={orgId} />,
      sortable: false,
      requiresAdmin: true,
      requiresOrg: true,
    },
    {
      header: UsersColumnKeys.View,
      label: '',
      renderFn: (user: User) => <Button onClick={() => handleRowClick(user)}>{common.View}</Button>,
      sortable: false,
    },
  ]
}

export function getSortUserCompareFn(sortBy: SortBy<UsersColumnKeys>): (a: User, b: User) => number {
  switch (sortBy.field) {
    case UsersColumnKeys.Name:
      return (a: User, b: User) =>
        normalizeCompareResult(invertIfDescending(simpleSort(getNameFromUser(a), getNameFromUser(b)), sortBy.order))

    case UsersColumnKeys.Email:
      return (a: User, b: User) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.attributes.email, b.attributes.email), sortBy.order))

    case UsersColumnKeys.Provider:
      return (a: User, b: User) => {
        const aprop = a.attributes.provider ? a.attributes.provider : ''
        const bprop = b.attributes.provider ? b.attributes.provider : ''
        return normalizeCompareResult(invertIfDescending(simpleSort(aprop, bprop), sortBy.order))
      }

    case UsersColumnKeys.LastCheckIn:
      return (a: User, b: User) =>
        normalizeCompareResult(
          invertIfDescending(lastUpdatedSort(a.attributes.lastCheckedInAt, b.attributes.lastCheckedInAt), sortBy.order),
        )

    case UsersColumnKeys.Membership:
      return (a: User, b: User) =>
        normalizeCompareResult(
          invertIfDescending(simpleSort(getMembershipFromUser(a), getMembershipFromUser(b)), sortBy.order),
        )

    default:
      console.warn(`Unhandled sort column ${sortBy.field}`)
      return () => 0
  }
}

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function lastUpdatedSort(a: any, b: any) {
  if (!a) return -1
  if (!b) return 1
  return differenceInMilliseconds(parseTime(a), parseTime(b))
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function downloadCsv(users: User[]) {
  const common: TranslationGroup = trans.common()
  const fields = [common.name, common.email, common.last_check_in, common.membership, common.Roles]
  const data = users.map(user => [
    getNameFromUser(user),
    user.attributes.email,
    parseTime(user.attributes.lastCheckedInAt).toISOString(),
    getMembershipFromUser(user, '-'),
    getGrantsFromUser(user, ', '),
  ])

  const name = `${Orgs.getSelectedOrgName()} ${common.users}`.trim()

  generateCSV(data, fields, name)
}
